/*
 * Chapter-1
 */
$chapter1-theme-color-pink: #ca7a92;
$chapter1-theme-color-green: #a8cbc6;
$chapter1-theme-color-purple: #4a3f55;
$chapter1-theme-color-purple__light: #817487;
$chapter1-theme-color-blue: #79a7b5;
$chapter1-theme-color-brown: #655046;

#chapter1 {
    position: absolute;
    width: 100%; height: 100%;
    left: 0; top: 0;
    overflow: hidden;
}
.chapter1-open div {
    position: absolute;
    width: 100%; height: 100%;
    left: 0; top: 0;
    transform-origin: center;
    transform: scaleY(0);
    &:nth-child(1) { background-color: $chapter1-theme-color-pink; }
    &:nth-child(2) { background-color: $chapter1-theme-color-purple; }
    &:nth-child(3) { background-color: $chapter1-theme-color-green; }
}
.chapter1-effects {
    position: absolute;
    font-size: 10px;
    transform: rotate(-45deg);
    width: 40vmin; height: 40vmin;
    left: calc(50% - 20vmin); top: calc(50% - 20vmin);
    transform-origin: center;
    .chapter1-effects-outside {
        width: inherit; height: inherit;
        .lines {
            position: absolute;
            width: inherit; height: inherit;
            div {
                position: absolute;
                overflow: hidden;
                &::after {
                    content: ""; position: absolute;
                    width: 100%; height: 100%;
                    background-color: $theme-color-milk;
                }
            }
            div:nth-child(odd) { width: 100%; height: .3em; }
            div:nth-child(even) { width: .3em; height: 100%; }
            div:nth-child(1) {
                left: 0; top: 0;
                &::after { transform: translateX(-100%); }
            }
            div:nth-child(2) {
                right: 0; top: 0;
                &::after {
                    transform: translateY(-100%);
                }
            }
            div:nth-child(3) {
                right: 0; bottom: 0;
                &::after {
                    transform: translateX(100%);
                }
            }
            div:nth-child(4) {
                left: 0; bottom: 0;
                &::after {
                    transform: translateY(100%);
                }
            }
        }
        .points {
            position: absolute;
            width: inherit; height: inherit;
            div {
                position: absolute;
                width: 1.2em; height: 1.2em;
                border-radius: 50%;
                transform: scale(0);
                &::after {
                    content: ""; position: absolute;
                    border-radius: inherit;
                    width: 100%; height: 100%;
                    left: 0; top: 0;
                    background-color: $theme-color-milk;
                }
            }
            div:nth-child(1) { left: -.6em; top: -.45em; }
            div:nth-child(2) { right: -.45em; top: -.6em; }
            div:nth-child(3) { right: -.6em; bottom: -.45em; }
            div:nth-child(4) { left: -.45em; bottom: -.6em; }
        }
    }
    .chapter1-effects-inside {
        position: absolute;
        width: inherit; height: inherit;
        left: 0; top: 0;
        div {
            position: absolute;
            width: inherit; height: inherit;
            left: 0; top: 0;
        }
        div:nth-child(1) {
            background-color: $theme-color-milk;
            transform: scale(0);
        }
        div:nth-child(2) {
            box-sizing: border-box;
            border: $theme-color-milk solid .4em;
            transform: scale(0);
        }
    }
}
.chapter1-radio {
    .chapter1-radio-start {
        position: absolute;
        width: 16vmin; height: 16vmin;
        left: calc(50% - 8vmin); top: calc(50% - 8vmin);
        opacity: 0;
        &:before, &::after {
            content: ""; position: absolute;
        }
        &::before {
            width: 100%; height: 100%;
            left: 0; top: 0;
            border: 2px solid $theme-color-orchid;
            border-radius: 50%;
        }
        &::after {
            display: block;
            width: 0; height: 0;
            left: 38%; top: 34%;
            border-top: 3vmin solid transparent;
            border-left: 6vmin solid $theme-color-orchid;
            border-bottom: 3vmin solid transparent;
        }
    }
}
.chapter1-night {
    position: absolute;
    width: 100%; height: 100%;
    overflow: hidden;
    div {
        position: absolute;
        width: 200vmax; height: 200vmax;
        left: calc(50% - 100vmax); top: calc(50% -  100vmax);
        background-color: $theme-color-orchid;
        border-radius: 50%;
        transform: scale(0);
    }
}
.chapter1-cards {
    position: absolute;
    width: 100%; height: 100%;
    overflow: hidden;
    .group-1 {
        width: inherit; height: inherit;
        div {
            position: absolute;
            width: inherit; height: inherit;
            background-color: $chapter1-theme-color-pink;
            transform: translateX(-100%);
        }
    }
    .group-2 {
        position: inherit;
        width: inherit; height: inherit;
        left: 0; top: 0;
        display: flex;
        flex-wrap: wrap;
        div {
            position: relative;
            width: 100%; height: 25%;
            overflow: hidden;
        }
        div::after {
            content: ""; position: absolute;
            width: 100%; height: 100%;
            transform: translateY(100%);
            background-color: $chapter1-theme-color-purple;
        }
    }
    .group-3 {
        position: inherit;
        width: 100%; height: 100%;
        left: 0; top: 0;
        .part-1, .part-2 {
            position: relative;
            width: 100%; height: 50%;
            display: flex;
            overflow: hidden;
            div {
                position: relative;
                width: 12.5%; height: 100%;
                background-color: $chapter1-theme-color-green;
            }
        }
        .part-1 div { transform: translateY(-100%); }
        .part-2 div { transform: translateY(100%); }
    }
    .group-4 {
        position: inherit;
        width: 100%; height: 100%;
        left: 0; top: 0;
        div {
            position: inherit;
            width: 150vmax; height: 150vmax;
            background-color: $theme-color-orchid;
            border-radius: 50%;
            transform: scale(0);
        }
        div:nth-child(1) { left: -75vmax; top: -75vmax; }
        div:nth-child(2) { right: -75vmax; bottom: -75vmax; }
    }
}
.chapter1-kurukuru {
    position: absolute;
    width: 50vmin; height: 50vmin;
    left: calc(50% - 25vmin); top: calc(50% - 25vmin);
    .line-wrapper {
        position: absolute;
        width: 2px; height: 100%;
        left: calc(50% - 1px); top: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        &::before, &::after {
            content: ""; display: block;
            width: 4vmin; height: 4vmin;
            border-radius: 50%;
            background-color: $theme-color-milk;
            transform: scale(0);
        }
        .line {
            width: 100%; height: 0;
            background-color: $theme-color-milk;
        }
    }
}
.chapter1-close {
    position: absolute;
    width: 100%; height: 100%;
    left: 0; top: 0;
    display: flex;
    div {
        position: relative;
        width: 50%; height: 100%;
        &::after {
            content: ""; position: absolute;
            width: 100%; height: 100%;
            left: 0; top: 0;
            background-color: $theme-color-milk;
        }
    }
    div:nth-child(1)::after { transform: translateX(-100%); }
    div:nth-child(2)::after { transform: translateX(100%); }
}
/*
 * Animation
 */
.chapter1-open div {
    &:nth-child(1) { animation: scaleY0-1 .8s ease 1.3s forwards; }
    &:nth-child(2) { animation: scaleY0-1 .8s ease 1.5s forwards; }
    &:nth-child(3) { animation: scaleY0-1 .8s ease 1.7s forwards; }
}
.chapter1-effects {
    animation: chapter1-effects-out 1s ease 4.55s forwards;
    .chapter1-effects-outside {
        .lines {
            div:nth-child(1)::after { animation: left-slide-in   .8s ease 3.0s forwards; }
            div:nth-child(2)::after { animation: top-slide-in    .8s ease 3.0s forwards; }
            div:nth-child(3)::after { animation: right-slide-in  .8s ease 3.0s forwards; }
            div:nth-child(4)::after { animation: bottom-slide-in .8s ease 3.0s forwards; }
        }
        .points {
            div { animation: scale0-1 .4s ease 2.5s forwards, fadeout .4s ease 3.9s forwards; }
            div:nth-child(1)::after { animation: chapter1-effects-points__sub1 .8s ease 3.0s forwards; }
            div:nth-child(2)::after { animation: chapter1-effects-points__sub2 .8s ease 3.0s forwards; }
            div:nth-child(3)::after { animation: chapter1-effects-points__sub3 .8s ease 3.0s forwards; }
            div:nth-child(4)::after { animation: chapter1-effects-points__sub4 .8s ease 3.0s forwards; }
        }
    }
    .chapter1-effects-inside {
        div:nth-child(1) { animation: scale0-1 .4s cubic-bezier(1,.1,.49,.96) 4.0s forwards; }
        div:nth-child(2) { animation: chapter1-effects-inside-scale .6s cubic-bezier(1,.1,.49,.96) 3.95s forwards; }
    }
}
.chapter1-radio {
    .chapter1-radio-start {
        animation: chapter1-radio-in .45s ease 5.55s forwards;
        &::after { animation: fadein .1s linear 6s alternate 3 forwards; }
    }
}
.chapter1-night {
    div { animation: scale0-1 1.5s ease 6.3s forwards; }
}
.chapter1-kurukuru {
    animation: chapter1-kurukuru-out 1s ease 11.3s forwards;
    .line-wrapper:nth-child(1) { animation: rotate-1-turn 3s linear 7.0s infinite; }
    .line-wrapper:nth-child(2) { animation: rotate-1-turn 3s linear 7.2s infinite; }
    .line-wrapper:nth-child(3) { animation: rotate-1-turn 3s linear 7.4s infinite; }
    .line-wrapper:nth-child(4) { animation: rotate-1-turn 3s linear 7.6s infinite; }
    .line-wrapper:nth-child(5) { animation: rotate-1-turn 3s linear 7.8s infinite; }
    .line-wrapper:nth-child(6) { animation: rotate-1-turn 3s linear 8.0s infinite; }
    .line-wrapper:nth-child(7) { animation: rotate-1-turn 3s linear 8.2s infinite; }
    .line-wrapper:nth-child(8) { animation: rotate-1-turn 3s linear 8.4s infinite; }
    .line-wrapper:nth-child(1) { &::before, &::after { animation: scale0-1 .5s ease-out 7.0s alternate infinite; }}
    .line-wrapper:nth-child(2) { &::before, &::after { animation: scale0-1 .5s ease-out 7.2s alternate infinite; }}
    .line-wrapper:nth-child(3) { &::before, &::after { animation: scale0-1 .5s ease-out 7.4s alternate infinite; }}
    .line-wrapper:nth-child(4) { &::before, &::after { animation: scale0-1 .5s ease-out 7.6s alternate infinite; }}
    .line-wrapper:nth-child(5) { &::before, &::after { animation: scale0-1 .5s ease-out 7.8s alternate infinite; }}
    .line-wrapper:nth-child(6) { &::before, &::after { animation: scale0-1 .5s ease-out 8.0s alternate infinite; }}
    .line-wrapper:nth-child(7) { &::before, &::after { animation: scale0-1 .5s ease-out 8.2s alternate infinite; }}
    .line-wrapper:nth-child(8) { &::before, &::after { animation: scale0-1 .5s ease-out 8.4s alternate infinite; }}
    .line-wrapper:nth-child(1) .line { animation: chapter1-kurukuru-ch 1s ease-out 7.0s infinite; }
    .line-wrapper:nth-child(2) .line { animation: chapter1-kurukuru-ch 1s ease-out 7.2s infinite; }
    .line-wrapper:nth-child(3) .line { animation: chapter1-kurukuru-ch 1s ease-out 7.4s infinite; }
    .line-wrapper:nth-child(4) .line { animation: chapter1-kurukuru-ch 1s ease-out 7.6s infinite; }
    .line-wrapper:nth-child(5) .line { animation: chapter1-kurukuru-ch 1s ease-out 7.8s infinite; }
    .line-wrapper:nth-child(6) .line { animation: chapter1-kurukuru-ch 1s ease-out 8.0s infinite; }
    .line-wrapper:nth-child(7) .line { animation: chapter1-kurukuru-ch 1s ease-out 8.2s infinite; }
    .line-wrapper:nth-child(8) .line { animation: chapter1-kurukuru-ch 1s ease-out 8.4s infinite; }
}
.chapter1-cards {
    .group-1 {
        div:nth-child(1) { animation: left-slide-in .6s ease 7.8s forwards; }
    }
    .group-2 {
        div:nth-child(1)::after { animation: bottom-slide-in .6s ease 8.6s forwards; }
        div:nth-child(2)::after { animation: bottom-slide-in .6s ease 8.6s forwards; }
        div:nth-child(3)::after { animation: bottom-slide-in .6s ease 8.6s forwards; }
        div:nth-child(4)::after { animation: bottom-slide-in .6s ease 8.6s forwards; }
    }
    .group-3 {
        .part-1 {
            div:nth-child(8) { animation: top-slide-in .6s ease 9.2s forwards; }
            div:nth-child(7) { animation: top-slide-in .6s ease 9.3s forwards; }
            div:nth-child(6) { animation: top-slide-in .6s ease 9.4s forwards; }
            div:nth-child(5) { animation: top-slide-in .6s ease 9.5s forwards; }
            div:nth-child(4) { animation: top-slide-in .6s ease 9.6s forwards; }
            div:nth-child(3) { animation: top-slide-in .6s ease 9.7s forwards; }
            div:nth-child(2) { animation: top-slide-in .6s ease 9.8s forwards; }
            div:nth-child(1) { animation: top-slide-in .6s ease 9.9s forwards; }
        }
        .part-2 {
            div:nth-child(8) { animation: bottom-slide-in .6s ease 9.2s forwards; }
            div:nth-child(7) { animation: bottom-slide-in .6s ease 9.3s forwards; }
            div:nth-child(6) { animation: bottom-slide-in .6s ease 9.4s forwards; }
            div:nth-child(5) { animation: bottom-slide-in .6s ease 9.5s forwards; }
            div:nth-child(4) { animation: bottom-slide-in .6s ease 9.6s forwards; }
            div:nth-child(3) { animation: bottom-slide-in .6s ease 9.7s forwards; }
            div:nth-child(2) { animation: bottom-slide-in .6s ease 9.8s forwards; }
            div:nth-child(1) { animation: bottom-slide-in .6s ease 9.9s forwards; }
        }
    }
    .group-4 {
        div:nth-child(1) { animation: scale0-1 .8s ease 10.5s forwards; }
        div:nth-child(2) { animation: scale0-1 .8s ease 10.5s forwards; }
    }
}
.chapter1-close {
    div:nth-child(1)::after { animation: left-slide-in .6s ease 11.3s forwards; }
    div:nth-child(2)::after { animation: right-slide-in .6s ease 11.3s forwards; }
}