/*
 * Keyframes - index
 */
@keyframes scale-in {
    0% { transform: scale(0); }
    40% { transform: scale(1.1); }
    60% { transform: scale(1); }
    80% { transform: scale(1.03); }
    100% { transform: scale(1); }
}
