/*
 * Profile page
 */
#profile-switch {
    position: absolute;
    z-index: 200;
    span {
        position: absolute;
        width: 100vw; height: 100vh;
        transform: rotate(90deg);
        transform-origin: right top;
    }
    span:nth-child(1) { background-color: $theme-profile-green; }
    span:nth-child(2) { background-color: $theme-profile-green-light; }
    span:nth-child(3) { background-color: $theme-profile-gray; }
}
#profile-switch[data-state="close"] span { transform: rotate(0deg); }

/* ------------------------------------------------------------------
 * body
 */
#profile-page {
    position: absolute;
    width: 100%; height: 100%;
    left: 0; top: 0;
    background-image: url("/images/profile/background.svg");
    background-size: 40px;
    color: $theme-index-black;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    text-align: center;
    opacity: 0;
    z-index: 300;
}
#profile-page[data-state="open"] { display: block; }
#profile-page[data-state="close"], #profile-page[data-state="init"] { display: none; }
.header {
    position: relative;
    width: 100%; height: 12.5vmin;
    margin: 0 auto;
    background-image: url("/images/profile/title.svg");
    background-size: 52vmin;
    background-position: center;
    background-repeat: no-repeat;
    margin-top: 9vmin;
}
.avatar {
    position: relative;
    margin: 0 auto;
    width: 120px; height: 120px;
    border: 4px solid $theme-index-black;
    border-radius: 50%;
    background-image: url("https://cdn.jsdelivr.net/npm/cyris/images/avatar.png");
    background-size: 120px;
    margin-top: 8vmin;
}
.author {
    position: relative;
    width: 19vmin; height: 5vmin;
    background-color: #fff;
    line-height: 5vmin;
    box-shadow: 0 0 5px $theme-index-black;
    margin: 0 auto;
    font-size: 23px;
    font-weight: bolder;
    letter-spacing: 2px;
    margin-top: -1vmin;
    transform: rotate(-3deg);
    &::before {
        position: absolute; content: "";
        width: 1.8vmin; height: 100%;
        background-color: $theme-index-black;
        left: 0; top: 0;
    }
}
.title {
    position: relative;
    width: $width-profile-content; height: 4.5vmin;
    line-height: 4.5vmin;
    margin: 0 auto;
    margin-top: 8vmin;
    font-size: 18px;
    letter-spacing: 3px;
    font-weight: 500;
    &::before, &::after {
        position: absolute; content: "";
        width: 65%; height: 2px;
        background-color: $theme-index-black;
    }
    &::before { left: 0; top: 0; }
    &::after { right: 0; bottom: 0; }
}
.description, .paragraph {
    position: relative;
    width: $width-profile-content;
    margin: 0 auto;
    letter-spacing: 4px;
    line-height: 31px;
    font-size: 16px;
    font-weight: 400;
}
.description { margin-top: 7vmin; }
.paragraph   { margin-top: 4vmin; }
.footer {
    padding-top: 10vmin;
    padding-bottom: 5vmin;
}
.information {
    position: relative;
    width: $width-profile-content; height: 340px;
    margin: 0 auto;
    background-color: #fff;
    box-shadow: 0 0 10px #9f9f9f;
    border-radius: 15px;
    margin-top: 5vmin;
    .inner {
        position: absolute;
        width: 100%; height: 80%;
        top: 10%;
        box-sizing: border-box;
        padding: 10px;
        background-color: $theme-profile-gray;
        &::before, &::after {
            position: absolute; content: ""; border-radius: 50%;
        }
        &::before {
            width: 6vmin; height: 6vmin;
            left: calc(50% - 3vmin); bottom: -3vmin;
            background-color: $theme-color-blue-green;
            box-shadow: 0 0 5px $theme-color-blue-green;
        }
        &::after {
            width: 3.2vmin; height: 3.2vmin;
            box-sizing: border-box;
            left: calc(50% - 1.6vmin); bottom: -1.6vmin;
            border: 3.4px solid #fff;
        }
        p {
            width: 100%;
            box-sizing: border-box;
            padding: 10px 20px;
            text-align: left;
            margin: 0;
            font-size: 14px;
            &::before { content: "\25C6"; position: relative; left: -10px; }
        }
    }
}
.project {
    position: relative;
    width: $width-profile-content;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 auto;
    margin-top: 5vmin;
    a {
        position: relative;
        width: 49%;
        box-sizing: border-box;
        padding: 6px;
        color: #4c4c4c;
        background-color: #fff;
        margin-bottom: 2vmin;
        box-shadow: 0 0 6px #9f9f9f;
        text-align: left;
        text-decoration: none;
        transition: all .4s ease;
        &:hover { color: #fff; background-color: $theme-profile-green; }
        img {
            display: block;
            width: 100%;
        }
        h4 { height: 20px; }
        p {
            height: 60px;
            padding: 0 2px;
            font-size: 14px;
        }
        time {
            display: block;
            text-align: right;
            padding: 10px 0;
            font-size: 12px;
        }
    }
}

/*
 * Animations
 */
#profile-switch[data-state="open"] span {
    &:nth-child(1) { animation: rotate90-0 .35s $cubic-profile-switch  0s forwards; }
    &:nth-child(2) { animation: rotate90-0 .35s $cubic-profile-switch .1s forwards; }
    &:nth-child(3) { animation: rotate90-0 .35s $cubic-profile-switch .2s forwards; }
}
#profile-switch[data-state="close"] span {
    &:nth-child(1) { animation: rotate0-n90 .35s $cubic-profile-switch .2s forwards; }
    &:nth-child(2) { animation: rotate0-n90 .35s $cubic-profile-switch .1s forwards; }
    &:nth-child(3) { animation: rotate0-n90 .35s $cubic-profile-switch  0s forwards; }
}
#profile-page {
    animation: fadein .35s ease .55s forwards;
}
