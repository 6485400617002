/*
 * Index Page (sp)
*/
@media screen and (max-width: 760px) {
    .index-bg {
        span:nth-child(1) { top:  30vmin; }
        span:nth-child(2) { left: 30vmin; }
        span:nth-child(3) { right: 15vmin; }
    }
    .index-logo {
        width: 80vmin;
        left: calc(50% - 40vmin);
        background-size: 80vmin;
    }
    .index-sns {
        bottom: 12vmin;
        width: 100%;
        transform: rotate(0);
        .inner {
            width: 100%;
            text-align: center;
            left: 0; top: calc(50% - 6vmin);
            a {
                width: 45px; height: 45px;
                font-size: 26px;
                line-height: 48px;
            }
        }
    }
    #index-button {
        width: 20vmin; height: 20vmin;
    }
    #index-button[data-state="init"], #index-button[data-state="close"] {
        .btn-main .short-id-2 span {
            &:nth-child(2) { transform: translateY(-3vmin); }
            &:nth-child(3) { transform: translateY( 3vmin); }
        }
    }
}