/*
 * Index page
 */
#index-page {
    position: absolute;
    width: 100%; height: 100%;
}
.index-bg {
    position: absolute;
    width: 100%; height: 100%;
    overflow: hidden;
    background-color: $theme-index-white;
    background-image: url("/images/index/diandian.svg");
    background-size: 30px;
    opacity: 0;
    span {
        position: absolute;
        display: block;
        box-sizing: border-box;
        width: 200vmax;
    }
    span:nth-child(1) {
        left: 0; top: 50vmin;
        transform-origin: left;
        transform: rotate(-30deg);
        border-top: 3px dashed $theme-index-black;
    }
    span:nth-child(2) {
        left: 50vmin; top: 0;
        transform-origin: left;
        transform: rotate(120deg);
        border-top: 3px dashed $theme-index-black;
    }
    span:nth-child(3) {
        right: 25vmin; bottom: 0;
        transform-origin: right;
        transform: rotate(105deg);
        border-top: 3px dashed $theme-index-black;
    }
}
.index-logo {
    position: absolute;
    width: 80vmin; height: 24vmin;
    left: calc(50% - 40vmin); top: calc(50% - 12vmin);
    background-image: url("/images/index/logo.svg");
    background-size: 80vmin;
    background-repeat: no-repeat;
    background-position: center;
    transform: scale(0);
}
#index-button {
    position: absolute;
    width: 15vmin; height: 15vmin;
    right: 5vmin; top: 5vmin;
    cursor: pointer;
    transform: scale(0);
    z-index: 1000;
    // common
    .btn-bg, .btn-main {
        position: absolute;
        width: 100%; height: 100%;
        left: 0; top: 0;
    }
    // bg
    .btn-bg::before, .btn-bg::after {
        position: absolute; content: "";
        width: 100%; height: 100%;
        background-color: $theme-index-black;
        opacity: .4;
    }
    // main
    .btn-main {
        background-color: $theme-index-black;
        &:hover { background-color: $theme-index-green; }
        transition: background-color .4s ease;
        .short-id {
            position: absolute;
            width: 80%; height: 80%;
            left: 10%; top: 10%;
        }
        .short-id-1 span {
            position: absolute;
            width: 25%; height: 25%;
            border: solid 1.2px $theme-index-white;
            &:nth-child(1) { left: 0; top: 0; border-right: transparent; border-bottom: transparent; }
            &:nth-child(2) { right: 0; top: 0; border-left: transparent; border-bottom: transparent; }
            &:nth-child(3) { right: 0; bottom: 0; border-left: transparent; border-top: transparent; }
            &:nth-child(4) { left: 0; bottom: 0; border-right: transparent; border-top: transparent; }
        }
        .short-id-2 span {
            position: absolute;
            width: 50%; height: 1.2px;
            background-color: $theme-index-white;
            left: 25%; top: calc(50% - 0.6px);
            transition: all .2s ease;
        }
    }
}
#index-button[data-state="close"], #index-button[data-state="init"] {
    .btn-main { &:hover { background-color: $theme-index-green; } }
    .btn-main .short-id-2 span {
        &:nth-child(1) { opacity: 1; }
        &:nth-child(2) { transform: translateY(-2vmin); }
        &:nth-child(3) { transform: translateY(2vmin); }
    }
}
#index-button[data-state="open"] {
    .btn-main { &:hover { background-color: $theme-index-red; } }
    .btn-main .short-id-2 span {
        &:nth-child(1) { opacity: 0; }
        &:nth-child(2) { transform: rotate(45deg) translateY(0); }
        &:nth-child(3) { transform: rotate(-45deg) translateY(0); }
    }
}
.index-sns {
    position: absolute;
    width: 200vmax; height: 3px;
    right: 0; bottom: 25vmin;
    transform-origin: right;
    transform: rotate(-15deg);
    box-sizing: border-box;
    border-top: 3px dashed $theme-index-black;
    opacity: 0;
    z-index: 100;
    .inner {
        position: absolute;
        width: auto; height: auto;
        right: 8%; top: calc(50% - 3.5vmin);
    }
    a {
        position: relative; display: inline-block;
        width: 50px; height: 50px;
        margin-right: 2.5vmin;
        text-align: center;
        border-radius: 50%;
        text-decoration: none;
        color: white;
        background-color: $theme-index-black;
        font-size: 28px;
        line-height: 52px;
        box-shadow: rgba(0,0,0,.3) 0 0 15px;
        transform: scale(0);
        &:hover { background-color: $theme-index-blue; }
        transition: background-color .4s ease;
    }
}

/*
 * Animations
 */
.index-bg { animation: fadein .6s ease forwards; }
.index-logo { animation: scale-in .6s ease .2s forwards; }
#index-button {
    animation: scale-in .6s ease forwards;
    .btn-bg {
        &::before { animation: rotate-1-turn 8s linear 0.4s infinite; }
        &::after  { animation: rotate-1-turn 9s linear 0.9s infinite; }
    }
}
.index-sns {
    animation: fadein .5s ease forwards;
    a:nth-child(1) { animation: scale-in .6s ease 0.6s forwards; }
    a:nth-child(2) { animation: scale-in .6s ease 0.7s forwards; }
    a:nth-child(3) { animation: scale-in .6s ease 0.8s forwards; }
    a:nth-child(4) { animation: scale-in .6s ease 0.9s forwards; }
    a:nth-child(5) { animation: scale-in .6s ease 1.0s forwards; }
}