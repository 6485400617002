/*
 * Prologue page
 */
#prologue {
    position: absolute;
    width: 100%; height: 100%;
    left: 0; top: 0;
    overflow: hidden;
}
.prologue-bg {
    position: absolute;
    width: 100%; height: 100%;
    left: 0; top: 0;
    div {
        position: absolute;
        width: 30%; height: 0;
        opacity: 0;
        transform-origin: center;
    }
    div:nth-child(1) {
        left: 0; top: 0;
        border-left: 0 solid transparent;
        border-right: 40vw solid transparent;
        border-top: 100vh solid $theme-color-milk;
    }
    div:nth-child(2) {
        right: 0; top: 0;
        border-left: 40vw solid transparent;
        border-right: 0 solid transparent;
        border-bottom: 100vh solid $theme-color-orchid;
    }
}
.prologue-frame {
    position: absolute;
    left: 5vmin; top: 5vmin;
    width: calc(100% - 10vmin); height: calc(100% - 10vmin);
    transform-origin: center;
    opacity: 0;
    span { position: absolute; }
    // left
    div:nth-child(1) {
        span:nth-child(1) {
            width: 70%; height: 0;
            left: 0; top: 0;
            border-bottom: $theme-color-orchid 2px dashed;
        }
        span:nth-child(2) {
            width: 0; height: 100%;
            left: 0; top: 0;
            border-right: $theme-color-orchid 2px dashed;
        }
        span:nth-child(3) {
            width: 30%; height: 0;
            left: 0; bottom: 0;
            border-top: $theme-color-orchid 2px dashed;
        }
    }
    // right
    div:nth-child(2) {
        span:nth-child(1) {
            width: 30%; height: 0;
            right: 0; top: 0;
            border-bottom: $theme-color-milk 2px dashed;
        }
        span:nth-child(2) {
            width: 0; height: 100%;
            right: 0; top: 0;
            border-left: $theme-color-milk 2px dashed;
        }
        span:nth-child(3) {
            width: 70%; height: 0;
            right: 0; bottom: 0;
            border-top: $theme-color-milk 2px dashed;
        }
    }
}
.prologue-stage {
    position: absolute;
    width: 70vw; height: 50vh;
    left: calc(50% - 35vw); top: calc(50% - 25vh);
    .prologue-stage-curtain {
        position: absolute;
        width: 100%; height: 100%;
        left: 0; top: 0;
        opacity: 0;
        div {
            position: absolute;
            width: 100%; height: 100%;
            &::before, &::after { content: ""; position: absolute; }
        }
        div:nth-child(1) {
            left: 0; top: 0;
            transform: translateX(calc(50% - 2px));
            &::before {
                width: 2px; height: 100%;
                background-color: $theme-color-orchid;
                left: 0; top: 0;
            }
            &::after {
                border-radius: 8vmin 0 8vmin 0;
                height: 8vmin; width: 4vmin;
                left: -4vmin; top: 0;
                background-color: $theme-color-orchid;
            }
        }
        div:nth-child(2) {
            right: 0; top: 0;
            transform: translateX(calc(-50% + 2px));
            &::before {
                width: 2px; height: 100%;
                background-color: $theme-color-milk;
                right: 0; top: 0;
            }
            &::after {
                border-radius: 8vmin 0 8vmin 0;
                height: 8vmin; width: 4vmin;
                right: -4vmin; bottom: 0;
                background-color: $theme-color-milk;
            }
        }
    }
    .prologue-stage-title {
        position: absolute;
        width: 100%; height: 100%;
        left: 0; top: 0;
        display: flex;
        div {
            position: relative;
            width: 50%; height: 100%;
            background-size: 70%;
            background-repeat: no-repeat;
            background-position: center;
            opacity: 0;
        }
        div:nth-child(1) { background-image: url("/images/prologue/progolue-title-part1.svg");}
        div:nth-child(2) { background-image: url("/images/prologue/progolue-title-part2.svg");}
    }
}
.prologue-skip {
    position: absolute;
    width: 12.8vmin; height: 12.8vmin;
    right: 8vmin; bottom: 8vmin;
    background-color: $theme-prologue-brown;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0 0 5px $theme-color-orchid;
    &::before {
        content: ""; position: absolute;
        width: 100%; height: 100%;
        left: 0; top: 0;
        border-radius: 50%;
        background-color: $theme-color-green;
        transform: scale(0);
        transition: all .3s ease;
    }
    &::after {
        content: ""; position: absolute;
        width: 100%; height: 100%;
        left: 0; top: 0;
        background-image: url("/images/prologue/progolue-skip.svg");
        background-repeat: no-repeat;
        background-size: 8vmin;
        background-position: center;
    }
    &:hover::before { transform: scale(1); }
}
.prologue-cover {
    div {
        position: absolute;
        width: 200vmax; height: 10vmax;
        bottom: 0;
        transform: rotate(-45deg) translate(-200vmax, -200vmax);
        transform-origin: center;
        background-color: $theme-color-orchid;
    }
    div:nth-child(1) { right: -100vmax; }
    div:nth-child(2) { right: -90vmax; }
    div:nth-child(3) { right: -80vmax; }
    div:nth-child(4) { right: -30vmax; height: 200vmax; }
}
.prologue-wave {
    position: absolute;
    width: 80vmin; height: 80vmin;
    left: calc(50% - 40vmin); top: calc(50% - 40vmin);
    &::before, &::after {
        content: ""; position: absolute;
        width: 100%; height: 100%;
        left: 0; top: 0;
        border-radius: 50%;
        transform: scale(0);
        transform-origin: center;
    }
    &::before { background-color: $theme-color-milk; }
    &::after  { background-color: $theme-color-orchid; }
}
.prologue-logo {
    position: absolute;
    width: 14vmin; height: 14vmin;
    left: calc(50% - 7vmin); top: calc(50% - 7.5vmin);
    background-image: url("/images/prologue/logo.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    transform: scale(0);
}
.prologue-out {
    position: absolute;
    width: 100%; height: 100%;
    left: 0; top: 0;
    display: flex;
    flex-wrap: wrap;
    div {
        position: relative;
        width: 100%; height: 25%;
        background-color: $theme-color-milk;
        transform-origin: center;
        transform: translateX(100%);
    }
}

/*
 * Animations
 */
.prologue-bg {
    div:nth-child(1) { animation: prologue-bg-in__left  .8s cubic-bezier(.59,.04,.27,1.02) forwards; }
    div:nth-child(2) { animation: prologue-bg-in__right .8s cubic-bezier(.59,.04,.27,1.02) forwards; }
}
.prologue-frame {
    animation: prologue-frame-in .3s ease .8s forwards;
}
.prologue-stage {
    .prologue-stage-curtain {
        animation: fadein 1.4s ease 1.1s forwards,
                prologue-stage-rotate 1.4s cubic-bezier(.11,.48,.27,1.01) 1.1s forwards;
        div:nth-child(1)  {
            animation: prologue-stage-curtain-open__left .8s cubic-bezier(.41,.49,.28,1.01) 2.2s forwards;
        }
        div:nth-child(2) {
            animation: prologue-stage-curtain-open__right .8s cubic-bezier(.41,.49,.28,1.01) 2.2s forwards;
        }
    }
    .prologue-stage-title {
        div:nth-child(1) {
            animation: prologue-stage-title-in__left .8s cubic-bezier(.41,.49,.28,1.01) 2.45s forwards;
        }
        div:nth-child(2) {
            animation: prologue-stage-title-in__right .8s cubic-bezier(.41,.49,.28,1.01) 2.45s forwards;
        }

    }
}
.prologue-cover {
    div:nth-child(1) { animation: prologue-cover-in 1.8s ease 3.0s forwards; }
    div:nth-child(2) { animation: prologue-cover-in 1.8s ease 3.1s forwards; }
    div:nth-child(3) { animation: prologue-cover-in 1.8s ease 3.2s forwards; }
    div:nth-child(4) { animation: prologue-cover-in 1.8s ease 3.3s forwards; }
}
.prologue-wave {
    &::before { animation: scale0-1 .4s ease 4.8s forwards; }
    &::after  { animation: scale0-1 .4s ease 4.9s forwards; }
}
.prologue-logo {
    animation: prologue-logo-in .5s ease 5.3s forwards;
}
.prologue-out {
    div:nth-child(1) { animation: right-slide-in 1s ease 6.1s forwards; }
    div:nth-child(2) { animation: right-slide-in 1s ease 6.2s forwards; }
    div:nth-child(3) { animation: right-slide-in 1s ease 6.3s forwards; }
    div:nth-child(4) { animation: right-slide-in 1s ease 6.4s forwards; }
}