@font-face{
    font-family: 'MF';
    font-weight: normal;
    src: url('../../../../public/fonts/MF.ttf');
}
/*
 * Open chapter
 */
.open-chapter {
    position: absolute;
    width: 100%; height: 100%;
    left: 0; top: 0;
    background-color: $theme-color-milk;
    display: flex;
    align-items: center;
    justify-content: center;
}
.chapter-bg {
    position: absolute;
    width: 100%; height: 100%;
    left: 0; top: 0;
    background-image: url("/images/chapters/dots.svg");
    background-size: 15px;
    opacity: 0;
}
.chapter-title {
    position: relative;
    width: 85vmin;
    font-size: 10px;
    color: $theme-color-orchid;
    text-align: center;
    &::before {
        content: ""; position: absolute;
        width: 100%; height: 4px;
        left: 0; top: 0;
        background-color: $theme-color-orchid;
        transform-origin: center;
        transform: scaleX(0);
    }
    .chapter-description {
        position: relative;
        width: 100%;
        margin-top: .4em;
        font-family: "MF", serif;
        font-size: 4.5em;
        line-height: 2.4em;
        border-top: 2px solid;
        border-bottom: 2px solid;
        box-sizing: border-box;
        color: rgba(65, 66, 75, 0);
        border-color: rgba(65, 66, 75, 0);
        overflow: hidden;
        &::after {
            content: ""; position: absolute;
            width: 100%; height: 100%;
            left: 0; top: 0;
            transform: rotate(-4deg) translateY(-150%);
            background-color: $theme-color-orchid;
        }
    }
    .chapter-name {
        position: relative;
        width: 100%;
        margin-top: 1em;
        font-family: 'Montserrat', 'Baskerville10Pro', serif;
        font-size: 2em;
        font-weight: 500;
        letter-spacing: .2em;
        opacity: 0;
    }
}

/*
 * Animations
 */
.chapter-bg { animation: fadein .4s ease forwards; }
.chapter-title {
    &::before { animation: scaleX0-1 .4s ease forwards; }
    .chapter-description {
        &::after { animation: chapter-description-after-in 1s ease .25s forwards; }
        animation: chapter-description-in .1s ease .6s forwards;
    }
    .chapter-name { animation: chapter-name-in .4s ease .8s forwards; }
}