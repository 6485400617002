/*
 * Keyframes - chapter 1
 */
$points-move-length1: calc(40vmin - .15em);
$points-move-length2: calc(-40vmin + .15em);

@keyframes chapter1-effects-points__sub1 {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX($points-move-length1);
    }
}
@keyframes chapter1-effects-points__sub2 {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY($points-move-length1);
    }
}
@keyframes chapter1-effects-points__sub3 {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX($points-move-length2);
    }
}
@keyframes chapter1-effects-points__sub4 {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY($points-move-length2);
    }
}
@keyframes chapter1-effects-inside-scale {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1.6);
    }
}
@keyframes chapter1-effects-out {
    0% {
        transform: scale(1) rotate(45deg);
    }
    45% {
        transform: scale(.85) rotate(90deg);
    }
    100% {
        transform: scale(6) rotate(0deg);
    }
}
@keyframes chapter1-radio-in {
    0% {
        opacity: 0;
        transform: scale(1.2);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}
@keyframes chapter1-kurukuru-ch {
    0% {
        height: 0;
    }
    50% {
        height: calc(100% - 8vmin);
    }
    100% {
        height: 0;
    }
}
@keyframes chapter1-kurukuru-out {
    0% {
        transform: scale(1) translateY(0);
    }
    100% {
        transform: scale(0) translateY(10%);
    }
}