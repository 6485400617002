/*
 * Theme Colors
 */
// Main
$theme-color-milk: #F0EEEA;
$theme-color-orchid: #41424B;
$theme-color-green: #7fa99b;
$theme-color-blue-green: #00aca5;

// Prologue
$theme-prologue-brown: #a66161;

// Page-index
$theme-index-black: #4c4c4c;
$theme-index-white: #fffffb;
$theme-index-green: #008c7f;
$theme-index-blue:  #578ab9;
$theme-index-red:   #B5495B;
// Page-profile
$theme-profile-gray:  #eeeeee;
$theme-profile-green:  #4AA9A4;
$theme-profile-green-light: #88bb6c;

/*
 * Cubic-bezier
 */
$cubic-profile-switch: ease;

/*
 * Content-width
 */
$width-profile-content: 70vmin;
$width-profile-content__sp: 80vmin;