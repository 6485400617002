/*
 * Profile Page (sp)
 */
@media screen and (max-width: 760px) {
    .header {
        height: 15vmin;
        margin-top: 30vmin;
        background-size: 80vmin;
    }
    .avatar {
        width: 35vmin; height: 35vmin;
        background-size: 35vmin;
        border: 3px solid $theme-index-black;
        margin-top: 15vmin;
    }
    .author {
        width: 35vmin; height: 9vmin;
        line-height: 9vmin;
        font-size: 23px;
        font-weight: 600;
        margin-top: -2.5vmin;
        box-shadow: 0 0 2px #333333;
        &::before { width: 3vmin; }
    }
    .description, .paragraph { width: $width-profile-content__sp; }
    .description { margin-top: 13vmin; }
    .paragraph   { margin-top: 8vmin;  }
    .title {
        width: $width-profile-content__sp;
        height: 8vmin;
        line-height: 8vmin;
        font-size: 20px;
        margin-top: 12vmin;
        &::before, &::after { width: 75%; }
    }
    .information {
        width: $width-profile-content__sp;
        height: 340px;
        box-shadow: 0 0 6px #9f9f9f;;
        margin-top: 10vmin;
        .inner {
            &::before {
                width: 12vmin; height: 12vmin;
                left: calc(50% - 6vmin); bottom: -6vmin;
            }
            &::after {
                width: 6vmin; height: 6vmin;
                left: calc(50% - 3vmin); bottom: -3vmin;
                border-width: 3.45px;
            }
        }
    }
    .project {
        width: $width-profile-content__sp;
        margin-top: 10vmin;
        a {
            width: 100%;
            margin-bottom: 7vmin;
        }
    }
    .footer {
        padding-bottom: 8vmin;
    }
}
