/*
 * Prologue page (sp)
 */
@media screen and (max-width: 760px) {
    .prologue-stage {
        width: 74vw; height: 30vh;
        left: calc(50% - 37vmin); top: calc(50% - 15vh);
    }
    .prologue-skip {
        right: 10vmin; bottom: 10vmin;
        width: 16vmin; height: 16vmin;
        &::after { background-size: 10vmin; }
    }
    .prologue-logo {
        width: 20vmin; height: 20vmin;
        left: calc(50% - 10vmin); top: calc(50% - 10vmin);
    }
}